import Swiper from 'swiper';
import 'swiper/css';

new Swiper('.swiper-testimonials', {
  slidesPerView: 1,
  spaceBetween: 24,
  loop: true,
  navigation: {
    nextEl: '.testimonials-button-next',
    prevEl: '.testimonials-button-prev',
  },
});
